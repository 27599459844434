import React from 'react'
import styles from './styles.module.css'
import { ILocationFilters } from '../../../work-orders/components/main/WorkOrdersMain'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import NewVanModal from '../../modal/new-van-modal/NewVanModal'
import { IRefreshCounterProps } from '../VansMain'

interface IVanPageHeaderProps {
  locationFilters: ILocationFilters
  refresh: IRefreshCounterProps
}
function VansPageHeader(props: IVanPageHeaderProps) {
  const { setLocationFilter } = props.locationFilters
  const { refreshCounter, setRefreshCounter } = props.refresh

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClosed = () => {
    setOpen(false)
  }

  return (
    <>
      {/* {open && (
        <NewVanModal
          isOpen={open}
          handleClosed={handleClosed}
          refresh={{ refreshCounter, setRefreshCounter }}
        />
      )} */}
      <div className={styles.vanPageHeaderContainer}>
        <div className={styles.inputsContainer}>
          <LocationDropDown
            onLocationChange={(val: string) => {
              setLocationFilter(val)
            }}
          />
        </div>
        {/* <div className={styles.inputsContainer}>
          <PrimaryButton
            buttonName="New Van"
            onClick={handleOpen}
          />
        </div> */}
      </div>
    </>
  )
}

export default VansPageHeader
