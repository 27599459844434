import React, { createContext, useState } from 'react'
import { IOrder, IOrderPartial } from '../../../../../app/entities/Order'
import { IOrderService } from '../../../../../app/entities/OrderService'
import { OrderVehicle } from '../../../../../app/entities/OrderVehicle'
import { ITireRackOrder } from '../../../../../app/entities/TireRackOrder'
import { fetchData } from '../../../global/utils/fetch'
import { getOrderDataById } from './api'
import { initialOrderValues } from './initial-values'
import {
  initialCustomerInfoProps,
  initialCustomerVehicleProps,
  initialTiresCombinationProps,
  initialInstallationPointProps,
} from '../../../global/constants/scheduler'
import {
  ITiresCombination,
  TiresCombination,
} from '../../../../../app/entities/TiresCombination'
import { ITire } from '../../../../../app/entities/Tire'
import {
  CustomerVehicle,
  ICustomerVehiclePartial,
} from '../../../../../app/entities/CustomerVehicle'
import moment from 'moment'
import {
  IInstallationPoint,
  InstallationPoint,
} from '../../../../../app/entities/InstallationPoint'

interface IContextProps {
  clearOrderData: () => void
  queryOrderData: (id?: string) => Promise<IGetOrderDataByIdResponse>
  resetOrderData: () => IGetOrderDataByIdResponse
  setOrderData: (orderData: IOrderPartial) => void
  /* The setters below are used to update specific fields in the
   * orderData object. This can be useful when multiple props of
   * orderData need to be updated at once across different useEffects
   * (typically in different components). More can be added as needed.
   */
  setInstallationPoint: (
    installationPoint: IInstallationPoint,
    removeDrivingDetails?: boolean,
  ) => void
  setCustomerVehicle: (customerVehicle: CustomerVehicle) => void
  setOrderNotes: (note: string) => void
  setTiresCombination: (tiresCombinationData: ITiresCombination) => void
  orderData: IGetOrderDataByIdResponse
  isOrderDataLoading: boolean
  // 'shortcuts' to access common nested props in orderData that
  selectedTimes: number[]
  customerVehicleData?: CustomerVehicle
  tiresCombinationData?: TiresCombination
}

interface IProviderProps {
  children: React.ReactNode
}

// Created this interface because endpoint added some additional legacy fields, so the return no longer matches IOrder entity
export interface IGetOrderDataByIdResponse extends IOrder {
  services: IOrderService[]
  tireRackOrder: ITireRackOrder | null
  cancelled_onsite_flag: number
  scheduleLeadTime: number
}

const initialFormData: IGetOrderDataByIdResponse = {
  ...initialOrderValues,
  customer: initialCustomerInfoProps as any,
  orderVehicles: [
    {
      customerVehicle: initialCustomerVehicleProps as any,
      tiresCombination: initialTiresCombinationProps as any,
    } as any,
  ],
  installationPoint: initialInstallationPointProps as any,
}

export const OrderContext = createContext<IContextProps>({} as IContextProps)

export function OrderContextProvider({ children }: IProviderProps) {
  const [orderDataState, setOrderDataState] =
    useState<IGetOrderDataByIdResponse>(initialFormData)
  const [orderDataOriginal, setOrderDataOriginal] =
    useState<IGetOrderDataByIdResponse>(initialFormData)
  const [isOrderDataLoading, setIsOrderDataLoading] = useState(false)

  function clearOrderData() {
    setOrderDataState(initialFormData)
  }

  async function queryOrderData(orderObjectId: string | undefined) {
    setIsOrderDataLoading(true)
    if (orderObjectId) {
      try {
        const orderResults = await fetchData<IGetOrderDataByIdResponse>(
          getOrderDataById(orderObjectId),
        )
        setOrderData(orderResults)
        setOrderDataOriginal(orderResults)
        return orderResults
      } catch (error) {
        console.error('Failed to fetch order data:', error)
        throw error
      } finally {
        setIsOrderDataLoading(false)
      }
    } else {
      return orderDataState
    }
  }

  function resetOrderData(): IGetOrderDataByIdResponse {
    setOrderData(orderDataOriginal)

    return orderDataOriginal
  }

  function setOrderData(newData: Partial<IGetOrderDataByIdResponse>) {
    setOrderDataState((prevData) => ({
      ...prevData,
      ...newData,
      orderVehicles: prevData.orderVehicles.map(
        (orderVehicle, index) =>
          ({
            ...orderVehicle,
            ...newData.orderVehicles?.[index],
            customerVehicle: {
              ...orderVehicle.customerVehicle,
              ...(newData.orderVehicles?.[index]?.customerVehicle || {}),
            } as any,
            tiresCombination: {
              ...orderVehicle.tiresCombination,
              ...(newData.orderVehicles?.[index]?.tiresCombination || {}),
            } as any,
          } as any),
      ),
      installationPoint: {
        ...prevData.installationPoint,
        ...newData.installationPoint,
      } as any,
      customer: {
        ...prevData.customer,
        ...newData.customer,
      } as any,
    }))
  }

  function setCustomerVehicle(customerVehicle: ICustomerVehiclePartial) {
    setOrderDataState((prevData) => ({
      ...prevData,
      orderVehicles: prevData.orderVehicles.map(
        (orderVehicle, index) =>
          ({
            ...orderVehicle,
            customerVehicle: {
              ...orderVehicle.customerVehicle,
              ...customerVehicle,
            } as any,
          } as any),
      ),
    }))
  }

  function setOrderNotes(note: string) {
    setOrderDataState((prevData) => {
      return {
        ...prevData,
        note,
      }
    })
  }

  function setTiresCombination(newTiresCombination: ITiresCombination) {
    const orderVehicle = orderDataState.orderVehicles[0]
    const tiresCombination = orderVehicle.tiresCombination
    const { frontTire, rearTire } = newTiresCombination

    setOrderDataState((prevData) => ({
      ...prevData,
      orderVehicles: [
        {
          ...orderVehicle,
          tiresCombination: {
            ...tiresCombination,
            ...newTiresCombination,
            frontTire: {
              ...(tiresCombination?.frontTire || {}),
              ...frontTire,
            } as ITire,
            rearTire: {
              ...(tiresCombination?.rearTire || {}),
              ...rearTire,
            } as ITire,
          } as ITiresCombination,
        } as OrderVehicle,
      ],
    }))
  }

  function setInstallationPoint(
    newInstallationPoint: IInstallationPoint,
    removeDrivingDetails = false,
  ) {
    setOrderDataState((prevData) => {
      return {
        ...prevData,
        installationPoint: {
          ...prevData.installationPoint,
          ...newInstallationPoint,
          drivingDetails: {
            ...(removeDrivingDetails
              ? {}
              : prevData.installationPoint?.drivingDetails),
            ...newInstallationPoint?.drivingDetails,
          },
        } as InstallationPoint,
      }
    })
  }

  return (
    <OrderContext.Provider
      value={{
        clearOrderData,
        queryOrderData,
        resetOrderData,
        setOrderData,
        setCustomerVehicle,
        setInstallationPoint,
        setOrderNotes,
        setTiresCombination,
        orderData: orderDataState,
        isOrderDataLoading: isOrderDataLoading,
        customerVehicleData: orderDataState.orderVehicles[0]?.customerVehicle,
        tiresCombinationData: orderDataState.orderVehicles[0]?.tiresCombination,
        selectedTimes: orderDataState.routeInstructions[0]?.startTime
          ? [
              moment
                .utc(orderDataState.routeInstructions[0]?.startTime)
                .valueOf(),
            ]
          : [],
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}
