import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import { InstallationPoint } from '../../../../../../../../app/entities/InstallationPoint'
import { SCHEDULER_MODES } from '../../../../../../global/constants/scheduler'
import EditAddress from '../../edit-address/EditAddress'
import { Box, Modal } from '@mui/material'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

interface IInstallationPointProps {
  chosenAddress: google.maps.GeocoderResult | null | undefined
  mode: string
  setChosenAddress: Dispatch<SetStateAction<google.maps.GeocoderResult | null>>
}

export default function InstallationAddressForm({
  chosenAddress,
  mode,
  setChosenAddress,
}: IInstallationPointProps) {
  const { orderData } = useContext(OrderContext)
  const { installationPoint } = orderData

  const [isZipCodeServiced, setIsZipCodeServiced] = useState<boolean>(true)
  const [useUnvalidatedAddress, setUseUnvalidatedAddress] =
    useState<boolean>(false)
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false)

  // If the address is unvalidated and the user confirmed it, we need to close this modal
  useEffect(() => {
    setIsEditAddressModalOpen(false)
  }, [useUnvalidatedAddress])

  const notValidAddress = !chosenAddress || !isZipCodeServiced

  return (
    <div>
      {isEditAddressModalOpen && (
        <Modal open={isEditAddressModalOpen}>
          <Box className={styles.modal}>
            <div className={`${styles.modalPageTitle} font--bold`}>
              {`Edit Address`}
            </div>
            <EditAddress
              chosenAddress={chosenAddress}
              isZipCodeServiced={isZipCodeServiced}
              setChosenAddress={setChosenAddress}
              setIsZipCodeServiced={setIsZipCodeServiced}
              setUseUnvalidatedAddress={setUseUnvalidatedAddress}
              handleClose={() => setIsEditAddressModalOpen(false)}
            />
          </Box>
        </Modal>
      )}
      <div className={styles.contactFormContainer}>
        <div className={`${styles.contactTitle} font--bold`}>
          Installation Address
          {mode === SCHEDULER_MODES.EDIT && (
            <button
              className={styles.editText}
              onClick={() => setIsEditAddressModalOpen(true)}
            >
              Edit
            </button>
          )}
        </div>

        <TextField
          className={styles.textfieldStyles}
          value={installationPoint?.address ?? ''}
          type='text'
          name='address'
          label='Address'
          onChange={() => {}}
          disabled={true}
          InputLabelProps={{
            shrink: Boolean(installationPoint?.address ?? ''), // Automatically shrink the label when value is not empty
          }}
        />
        <TextField
          className={styles.textfieldStyles}
          value={installationPoint?.aptOrSuite ?? ''}
          type='text'
          label='Apt or Suite'
          name='aptOrSuite'
          onChange={() => {}}
          disabled={true}
          InputLabelProps={{
            shrink: Boolean(installationPoint?.aptOrSuite ?? ''), // Automatically shrink the label when value is not empty
          }}
        />
        <TextField
          className={styles.textfieldStyles}
          value={installationPoint?.city ?? ''}
          type='text'
          name='city'
          label='City'
          onChange={() => {}}
          disabled={true}
          InputLabelProps={{
            shrink: Boolean(installationPoint?.city ?? ''), // Automatically shrink the label when value is not empty
          }}
        />
        <div className={styles.zipStateContainer}>
          <TextField
            value={installationPoint?.state ?? ''}
            type='text'
            name='state'
            label='State'
            onChange={() => {}}
            disabled={true}
            InputLabelProps={{
              shrink: Boolean(installationPoint?.state ?? ''), // Automatically shrink the label when value is not empty
            }}
          />
          <TextField
            type='tel'
            value={installationPoint?.zipCode ?? ''}
            name='zipCode'
            label='Zip'
            sx={{ width: 113 }}
            onChange={() => {}}
            disabled={true}
            InputLabelProps={{
              shrink: Boolean(installationPoint?.zipCode ?? ''), // Automatically shrink the label when value is not empty
            }}
          />
        </div>
      </div>
    </div>
  )
}
