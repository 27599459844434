import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import { IVan, IVanPartial } from '../../../../../app/entities/Van'
import { VAN_IMAGE_TYPES } from '../../../global/constants/images'
import { getImageBys3ObjectKey } from '../../api'
import { fetchData } from '../../../global/utils/fetch'
import CheckboxWrapper from '../../../global/components/checkbox/CheckboxWrapper'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../global/constants/permissions'
import userHasPermission from '../../../global/utils/user/has-permission'
interface INewVanFormProps {
  vanFormData: IVanPartial | null
  setVanFormData: Dispatch<SetStateAction<IVanPartial | null>>
  setSelectedLocation: Dispatch<SetStateAction<string>>
  selectedLocation?: string
  isReadOnly?: boolean
}
function VanFormComponent({
  vanFormData,
  setVanFormData,
  setSelectedLocation,
  selectedLocation,
  isReadOnly,
}: INewVanFormProps) {
  const { user } = useContext(UserContext)
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState('')
  const vanImages = vanFormData?.images
  const qrCodeObjectKey = vanImages?.length
    ? vanImages.find((image) => image.type === VAN_IMAGE_TYPES.qr_code)
        ?.s3objectKey
    : ''
  // const qrCodeUrl = useFetch(getImageBys3ObjectKey(qrCodeObjectKey))
  const getQrCodeImage = async () => {
    if (qrCodeObjectKey) {
      const qrCodeUrl = await fetchData<string>(
        getImageBys3ObjectKey(qrCodeObjectKey),
      )
      setQrCodeImageUrl(qrCodeUrl)
    }
  }
  useEffect(() => {
    if (qrCodeObjectKey) {
      getQrCodeImage()
    }
  }, [qrCodeObjectKey])

  return (
    <div className={styles.formContainer}>
      <LocationDropDown
        onLocationChange={(val: string) => {
          setSelectedLocation(val)
        }}
        defaultValue={selectedLocation && selectedLocation}
        disabled={isReadOnly}
      />
      <TextField
        name='name'
        label='Van Name'
        onChange={(e) =>
          setVanFormData({ ...(vanFormData as IVan), name: e.target.value })
        }
        value={vanFormData?.name}
        disabled={isReadOnly}
      />
      <div>
        <CheckboxWrapper
          isInitiallyChecked={vanFormData?.active ?? true}
          onChange={(e: any) =>
            setVanFormData({
              ...(vanFormData as IVan),
              active: e.target.checked,
            })
          }
          disabled={
            !userHasPermission(user).allow(
              PERMISSIONS.VANS.CATEGORY,
              PERMISSIONS.VANS.ENTRIES.EDIT_VANS.NAME,
            )
          }
        />
        Is Van Active?
      </div>
      <div
        className={styles.qrCodeContainer}
        style={{
          display: qrCodeImageUrl ? 'flex' : 'none',
        }}
      >
        <img src={qrCodeImageUrl} alt='qr code' />
      </div>
    </div>
  )
}

export default VanFormComponent
