export const associateBumpedOrdersToRouteArea = (body: {locationObjectId: string, date: string}) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/associate-bumped-orders-to-route-area`,
    body,
	}
}

export const runRouteOptimizationForLocation = (body: {locationId: string, date: number}) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/test/route-optimization`,
		body,
	}
}

export const getOrderByWorkOrderId = (workOrderId: number) => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/order-by-work-order-id?workOrderId=${workOrderId}`,
	}
}

export const addCompletedInvoice = (body: any) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/add-completed-invoice`,
		body,
	}
}