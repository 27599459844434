import { useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import ModalContent from './ModalContent'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
import CircularProgress from '@mui/material/CircularProgress'
import { use } from 'chai'
interface IScheduleModalProps {
  isOpen: boolean
  handleClosed: Function
  selectedOrder?: string
}
export default function WorkOrderDetailModal({
  isOpen,
  handleClosed,
  selectedOrder,
}: IScheduleModalProps) {
  const {
    isOrderDataLoading: isLoading,
    orderData: data,
    queryOrderData,
  } = useContext(OrderContext)

  useEffect(() => {
    if (selectedOrder) {
      queryOrderData(selectedOrder)
    }
  }, [selectedOrder])

  return (
    <div>
      <Modal open={isOpen} onClose={() => handleClosed()}>
        <Box className={styles.workOrderDetailModal}>
          {isLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {!isLoading && data && <ModalContent selectedOrder={selectedOrder} />}
        </Box>
      </Modal>
    </div>
  )
}
