import { useContext, useEffect, useState } from 'react'
import { ILocation } from '../../../../../app/entities/Location'
import { Route } from '../../../../../app/entities/Route'
import MainPageWrapper from '../../../global/components/MainPageWrapper'
import {
  castToUtc,
  getCurrentDate,
  to12HourTime,
} from '../../../global/utils/dates'
import useFetch from '../../../global/hooks/useFetchHook'
import WorkOrderDetailModal from '../../../work-orders/components/work-order-detail/components/main/WorkOrderDetailModal'
import AppointmentCalendar, { IEvent } from '../calendar/AppointmentCalendar'
import DayCalendarContainer from '../day-table/table-container/DayCalendar'
import NewAppointment from '../new-appointment/NewAppointment'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { LocationModal } from '../../../locations/components/location-modal/components/container-modal/LocationModal'
import { DaySetting } from '../../../../../app/entities/DaySetting'
import moment from 'moment'
import { OrderContext } from '../../../global/context/order-context/OrderContext'

function initialDate() {
  const savedDate = localStorage.getItem('selectedCalendarDate')
  return savedDate ? savedDate : getCurrentDate()
}

function initialLocation() {
  return localStorage.getItem('selectedLocationObjectId') ?? ''
}
function initialView() {
  return localStorage.getItem('calendarView') || 'month'
}

export default function AppointmentsMain() {
  const { clearOrderData } = useContext(OrderContext)
  const [view, setView] = useState(initialView())
  const [scheduleFilter, setScheduleFilter] = useState({
    date: initialDate(),
    locationId: initialLocation(),
  })

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState('')

  const [isLocationModalOpen, setIsLocationModalOpen] = useState<boolean>(false)
  const [locationObjectToEdit, setLocationObjectToEdit] = useState<
    string | undefined
  >(undefined)

  const { user } = useContext(UserContext)

  const userLocations = user?.locations || []

  useEffect(() => {
    localStorage.setItem('calendarView', view)
  }, [view])

  useEffect(() => {
    if (userLocations.length === 1) {
      setScheduleFilter({
        ...scheduleFilter,
        locationId: userLocations[0].objectId,
      })
      localStorage.setItem(
        'selectedLocationObjectId',
        userLocations[0].objectId,
      )
    }
  }, [userLocations])

  useEffect(() => {
    if (selectedOrder) {
      setIsOrderModalOpen(true)
    }
  }, [selectedOrder])

  // get routes
  const query = new URLSearchParams({
    locationObjectId: scheduleFilter.locationId,
    date: scheduleFilter.date,
    view: view,
  })

  const {
    data: routes = [],
    isLoading: isCalendarEventsLoading,
    refetch: refetchCalendarEvents,
  } = useFetch({
    method: 'GET',
    url: `${
      process.env.REACT_APP_ORIGIN
    }/scheduling/schedule?${query.toString()}`,
  })

  const scheduleFilterMonth = scheduleFilter.date
    ? scheduleFilter.date.split('-')[1]
    : ''

  const {
    data: daySettings = [],
    isLoading: isDaySettingsLoading,
    refetch: refetchDaySettings,
  } = useFetch({
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${scheduleFilter.locationId}/day-settings?month=${scheduleFilterMonth}`,
  })

  // get location
  const { data: location } = useFetch<ILocation | null>({
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${scheduleFilter.locationId}`,
  })

  function composeCalendarEventsFromRoutes(routes: Route[]) {
    return ((routes || []) as Route[]).reduce((acc: IEvent[], route) => {
      const routeInstructions = route.routeInstructions

      if (!routeInstructions || routeInstructions.length === 0) {
        return acc
      }

      const events = routeInstructions
        .map((instruction) => {
          const { order, startTime, endTime } = instruction

          if (!order?.orderVehicles[0]?.tiresCombination) return null

          return {
            title: {
              primary: `${to12HourTime(new Date(castToUtc(startTime)))} `,
              secondary: `${order?.customer?.firstName} ${
                order?.customer?.lastName
              } - ${
                order?.orderVehicles[0]?.tiresCombination?.count || 0
              } tires`,
            },
            start: castToUtc(startTime),
            end: castToUtc(endTime),
            resource: order?.status,
            onClick: () => {
              setSelectedOrder(order?.objectId)
            },
          } as IEvent
        })
        .filter((event) => event !== null) as IEvent[]

      return [...acc, ...events]
    }, [])
  }

  function composeCalendarEventsFromDaySettings(daySettings: DaySetting[]) {
    return ((daySettings || []) as DaySetting[]).map((daySetting) => {
      const { startTime, endTime, day } = daySetting

      const [composedStartTime, composedEndTime] = [startTime, endTime].map(
        (time) => {
          const [hours, minutes] = time.split(':').map(Number)
          const composed = moment
            .utc(day)
            .hours(hours)
            .minutes(minutes)
            .toDate()
          return castToUtc(composed)
        },
      )

      return {
        title: {
          secondary: ' - Blocked',
          primary: `${to12HourTime(composedStartTime)} - ${to12HourTime(
            composedEndTime,
          )}`,
        },
        start: composedStartTime,
        end: composedEndTime,
        resource: 'blocked',
        onClick: () => {},
      } as IEvent
    })
  }

  const daySettingCalendarEvents = composeCalendarEventsFromDaySettings(
    daySettings as DaySetting[],
  )

  const calendarEvents = [
    ...composeCalendarEventsFromRoutes(routes as Route[]),
    ...daySettingCalendarEvents,
  ]

  function onWorkOrderModalClosed() {
    clearOrderData()

    refetchCalendarEvents()
    refetchDaySettings()
    setIsOrderModalOpen(false)
    setSelectedOrder('')
  }

  function handleLocationModalClosed() {
    setIsLocationModalOpen(false)
    refetchDaySettings()
    setLocationObjectToEdit(undefined)
  }

  function onOpenLocationModal(locationObjectId?: string) {
    setLocationObjectToEdit(locationObjectId)
    setIsLocationModalOpen(true)
  }

  function renderCalendar() {
    switch (view) {
      case 'month':
        return (
          <AppointmentCalendar
            setView={setView}
            scheduleFilter={scheduleFilter}
            setScheduleFilter={setScheduleFilter}
            calendarEvents={calendarEvents}
            refetchCalendarEvents={refetchCalendarEvents}
            refetchDaySettings={refetchDaySettings}
            isCalendarEventsLoading={isCalendarEventsLoading}
            onOpenLocationModal={onOpenLocationModal}
          />
        )
      case 'day':
        return (
          <DayCalendarContainer
            setView={setView}
            scheduleFilter={scheduleFilter}
            setScheduleFilter={setScheduleFilter}
            routes={routes as Route[]}
            daySettings={daySettings as DaySetting[]}
            location={location}
            refetchCalendarEvents={refetchCalendarEvents}
            isDaySettingsLoading={isDaySettingsLoading}
            refetchDaySettings={refetchDaySettings}
            isCalendarEventsLoading={isCalendarEventsLoading}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            onOpenLocationModal={onOpenLocationModal}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <LocationModal
        isOpen={isLocationModalOpen}
        handleClosed={handleLocationModalClosed}
        isNew={!locationObjectToEdit}
        locationObjectId={locationObjectToEdit}
      />
      <MainPageWrapper
        titleFields={
          <NewAppointment
            refetchCalendarEvents={refetchCalendarEvents}
            location={location ?? undefined}
          />
        }
        title='Appointments'
      >
        {renderCalendar()}
        <WorkOrderDetailModal
          isOpen={isOrderModalOpen}
          handleClosed={onWorkOrderModalClosed}
          selectedOrder={selectedOrder}
        />
      </MainPageWrapper>
    </>
  )
}
