import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styles from './styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import { getDemandGenerationTableData } from './api'

interface WeeklyData {
  [weekKey: string]: number
}

interface DemandStore {
  demandStore: string
  weeklyData: WeeklyData
}

interface HubStoreGroup {
  hubStore: string
  demandStores: DemandStore[]
  total: WeeklyData
}
interface IDemandGenerationTableProps {
  weeks: number
}
const DemandGenerationTable: React.FC<IDemandGenerationTableProps> = ({
  weeks,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [tableState, setTableState] = useState<HubStoreGroup[]>([])
  // const [weeks, setWeeks] = useState<number>(2)

  const fetchTableData = async (weeks: number) => {
    const data = await fetchData<HubStoreGroup[]>(
      getDemandGenerationTableData(weeks),
    )
    setTableState(data)
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    fetchTableData(weeks)
  }, [weeks])

  const generateHeaders = () => {
    let headers = []
    for (let i = 0; i < weeks; i++) {
      const weekNumber = moment().subtract(i, 'weeks').week()
      headers.push(`wk${weekNumber}`)
    }
    return headers
  }
  const renderTableRows = () => {
    return tableState.flatMap((group) => {
      // Find if a demandStore matches the hubStore name
      const matchingDemandStore = group.demandStores.find(
        (store) => store.demandStore === group.hubStore,
      )
      const demandStoreRows = group.demandStores
        .filter((store) => store.demandStore !== group.hubStore) // Exclude the hubStore if it's also a demandStore
        .map((store) => (
          <tr key={store.demandStore} className={styles.row}>
            <td
              className={`${styles.emptyCell} ${styles.noVerticalBorders}`}
            ></td>
            <td className={styles.demandStoreCell}>{store.demandStore}</td>
            {generateHeaders().map((header) => (
              <td
                key={`${store.demandStore}-${header}`}
                className={styles.weeklyDataCell}
              >
                {store.weeklyData[header.toLowerCase().replace(' ', '')] || 0}
              </td>
            ))}
          </tr>
        ))

      // Prepare the hubStore row, including the matching demandStore if present
      const hubStoreRow = (
        <tr key={group.hubStore} className={styles.row}>
          <td className={`${styles.hubStoreCell}`}>{group.hubStore}</td>
          <td
            className={
              matchingDemandStore ? styles.demandStoreCell : styles.emptyCell
            }
          >
            {matchingDemandStore ? matchingDemandStore.demandStore : ''}
          </td>
          {generateHeaders().map((header) => (
            <td
              key={`${group.hubStore}-${header}`}
              className={styles.weeklyDataCell}
            >
              {matchingDemandStore
                ? matchingDemandStore.weeklyData[
                    header.toLowerCase().replace(' ', '')
                  ] || 0
                : 0}
            </td>
          ))}
        </tr>
      )

      return [
        hubStoreRow,
        ...demandStoreRows,
        <tr className={styles.totalRow}>
          <td className={`${styles.totalCell}`}>Total</td>
          <td className={`${styles.emptyCell} ${styles.weeklyTotalCell}`}></td>
          {generateHeaders().map((header) => (
            <td key={`total-${header}`} className={styles.weeklyTotalCell}>
              {group.total[header.toLowerCase().replace(' ', '')] || 0}
            </td>
          ))}
        </tr>,
      ]
    })
  }

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>
  }

  return (
    <div className={styles.tableWrapperContainer}>
      <table className={styles.demandGenerationTable}>
        <thead>
          <tr className={styles.headerRow}>
            <th className={styles.headerCell}>Hub Store</th>
            <th className={styles.headerCell}>Demand Store</th>
            {generateHeaders().map((header) => (
              <th key={header} className={styles.headerCell}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
    </div>
  )
}

export default DemandGenerationTable
