import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'

import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../constants/permissions'
import userHasPermission from '../../utils/user/has-permission'

const { CATEGORY, ENTRIES } = PERMISSIONS.ADMIN

interface ILocation {
  name: string
  objectId: string
}
interface LocationDropdownProps {
  onLocationChange?: Function
  disabled?: boolean
  defaultValue?: string | null
  valueOverride?: string | null
}

export default function LocationDropDown({
  onLocationChange,
  disabled,
  defaultValue,
  valueOverride,
}: LocationDropdownProps) {
  const [value, setValue] = useState(defaultValue || '')

  const { user } = useContext(UserContext)

  const userLocations = user?.locations || []

  const canViewAllLocations = userHasPermission(user).allow(
    CATEGORY,
    ENTRIES.VIEW_ALL_LOCATIONS.NAME,
  )

  useEffect(() => {
    // if there is only one location, set it as the default
    if (defaultValue && userLocations) {
      const defaultLocation = userLocations.find(
        (location: ILocation) => location.objectId === defaultValue,
      )
      if (defaultLocation) {
        setValue(defaultLocation.objectId)
      }
    }
  }, [userLocations, defaultValue])

  useEffect(() => {
    if (valueOverride) {
      setValue(valueOverride)
    }
  }, [valueOverride])

  const onLocationChangeWrapper = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      target: { value: newValue },
    } = event
    setValue(newValue)
    onLocationChange && onLocationChange(newValue)
  }

  const sortedLocations = (userLocations || [])
    .filter((location: ILocation) => location.name)
    .sort((a: ILocation, b: ILocation) => a.name?.localeCompare(b.name))

  let menuItems = sortedLocations.filter(Boolean).map((location: ILocation) => {
    return (
      <MenuItem
        key={location.objectId}
        value={location.objectId}
        className={styles.dropDownOption}
      >
        {location.name}
      </MenuItem>
    )
  })

  return (
    <div>
      <TextField
        className={styles.dropDownSelect}
        label='Location'
        select
        name='locationId'
        disabled={!canViewAllLocations || disabled}
        onChange={onLocationChangeWrapper}
        value={userLocations ? value : ''}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 250,
                fontFamily: '',
              },
            },
          },
        }}
      >
        {menuItems}
      </TextField>
    </div>
  )
}
