import { Chip } from '@mui/material'
import { useState } from 'react'
import { ITabulationArea } from '../../../../../../../app/entities/TabulationArea'
import styles from './styles.module.css'

interface IZipCodesManagerProps {
  tabulationAreas: Partial<ITabulationArea>[]
  setTabulationAreas: Function
  isReadOnly: boolean
}

export default function ZipCodesManager({
  tabulationAreas,
  setTabulationAreas,
  isReadOnly,
}: IZipCodesManagerProps) {
  const [zipCodeInputBackgroundColor, setZipCodeInputBackgroundColor] =
    useState('#f5f5f5')
  const [zipCodeInputValue, setZipCodeInputValue] = useState('')

  const zips = tabulationAreas
    ? tabulationAreas.map((tabulationArea) => tabulationArea.zipCode).sort()
    : []
  const inputChip = (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: isReadOnly ? undefined : 'text',
        backgroundColor: zipCodeInputBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Zip'
          style={{
            all: 'unset',
            width: '3.5rem',
            userSelect: 'none',
          }}
          value={zipCodeInputValue}
          disabled={isReadOnly}
          autoComplete='off'
          // allows for pasting multiple zips at once assuming
          // it is a list of zips separated by newlines
          onPaste={(e) => {
            const zipsString = e.clipboardData.getData('text/plain')

            const goodZips = zipsString
              .split('\n')
              .map((zip) => zip.trim())
              .filter((zip) => zip.length === 5)
              .filter(
                (zip) =>
                  !tabulationAreas.find(
                    (tabulationArea) => tabulationArea.zipCode === zip,
                  ),
              )

            setTabulationAreas([
              ...tabulationAreas,
              ...goodZips.map((zip) => ({ zipCode: zip })),
            ])

            setTimeout(() => {
              setZipCodeInputValue('')
            }, 1)
          }}
          onChange={(e) => {
            setZipCodeInputBackgroundColor('#f5f5f5')
            setZipCodeInputValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
        />
      }
    />
  )

  const zipChips = zips.map((zip) => {
    return (
      <Chip
        key={zip}
        label={zip}
        style={{
          backgroundColor: '#2a7187',
          color: 'white',
          fontWeight: 'bold',
        }}
        disabled={isReadOnly}
        onDelete={() => {
          setTabulationAreas(
            tabulationAreas.filter(
              (tabulationArea) => tabulationArea.zipCode !== zip,
            ),
          )
        }}
      />
    )
  })

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newZip = e.currentTarget.value
      if (newZip && newZip.length === 5) {
        if (
          tabulationAreas.find(
            (tabulationArea) => tabulationArea.zipCode === newZip,
          )
        ) {
          setZipCodeInputBackgroundColor('#fcebeb')
        } else {
          setTabulationAreas([
            ...tabulationAreas,
            {
              zipCode: newZip,
            },
          ])
          setZipCodeInputValue('')
        }
      }
    }
  }

  return (
    <div className={styles.zipCodesManagerMain}>
      <div className={styles.zipCodesManagerContainer}>
        <div className={styles.zipCodesManagerHeader}>
          <div className={`${styles.zipCodesManagerTitle} font--bold`}>
            Serviced Zip Codes
          </div>
          <div className={styles.zipCodesManagerSubtitle}>
            To input zip codes quickly, copy and paste zips from an Excel or
            Google Sheet.
          </div>
        </div>
        <div className={styles.zipCodesManagerChipsContainer}>
          {inputChip}
          {zipChips}
        </div>
      </div>
    </div>
  )
}
