import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useContext, useState, useEffect } from 'react'
import styles from '../styles.module.css'
import { IVan, IVanPartial } from '../../../../../../app/entities/Van'
import { ToastNotificationContext } from '../../../../global/context/toast-context/ToastNotificationContext'
import { IRefreshCounterProps } from '../../../main/VansMain'
import EditVanContentContainer from '../form/EditVanContentContainer'
import EditVanModalHeader from '../header/EditVanModalHeader'
import { fetchData } from '../../../../global/utils/fetch'
import { setVanToLocation, updateVan } from '../../../api'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
interface IEditVanModalProps {
  isOpen: boolean
  handleClosed: Function
  refresh: IRefreshCounterProps
  selectedRowData: IVanPartial | null
}

function EditVanModal({
  isOpen,
  handleClosed,
  refresh,
  selectedRowData: selectedVan,
}: IEditVanModalProps) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const { refreshCounter, setRefreshCounter } = refresh
  const [isLoading, setIsLoading] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<string>(
    selectedVan?.location?.objectId || '',
  )
  const [editVanFormData, setEditVanFormData] = useState<IVanPartial | null>(
    selectedVan,
  )

  useEffect(() => {
    setEditVanFormData(selectedVan)
    setSelectedLocation(selectedVan?.location?.objectId || '')
  }, [selectedVan])

  const handleEditVan = async (e: any) => {
    try {
      if (!editVanFormData) {
        return alert('Error: No Van Data, please refresh page.')
      }

      setIsLoading(true)
      await fetchData(updateVan(editVanFormData))
      // await fetchData(
      //   setVanToLocation(editVanFormData.objectId!, selectedLocation),
      // )
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully updated van!',
        severity: 'success',
      })
      setRefreshCounter(() => refreshCounter + 1)
      handleClosed(e)
    } catch (error) {
      console.error('Error updating van:', error)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error Updating Van',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        onClose={() => handleClosed()}
      >
        <Box className={styles.editVanModal}>
          <div className={styles.formContainer}>
            {/* <EditVanModalHeader
              handleClosed={handleClosed}
              handleEditVan={handleEditVan}
              isLoading={isLoading}
            /> */}
            {selectedVan?.objectId && selectedVan.objectId !== '' && (
              <EditVanContentContainer
                editVanFormData={selectedVan}
                setEditFormData={setEditVanFormData}
                setSelectedLocation={setSelectedLocation}
                selectedLocation={selectedLocation}
              />
            )}
            <div className={styles.buttonContainer}>
              <SecondaryButton
                style={{ width: 'fit-content' }}
                onClick={() => handleClosed()}
                disabled={isLoading}
                buttonName='Cancel'
              />
              <PrimaryButton
                style={{ width: 'fit-content' }}
                onClick={handleEditVan}
                disabled={isLoading}
                buttonName='Save'
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default EditVanModal
