import React from 'react'
import Checkbox from '@mui/material/Checkbox'

interface IPrimaryCheckboxProps {
  checked: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  name?: string
  disabled?: boolean
}

const checkBoxStyles = {
  color: '#D70000',
  '& .MuiSvgIcon-root': { fontSize: 28 },
  '&.Mui-checked': {
    color: '#D70000',
  },
  '&:hover': { backgroundColor: 'transparent' },
}

function PrimaryCheckbox(props: IPrimaryCheckboxProps) {
  return (
    <React.Fragment>
      <Checkbox
        sx={checkBoxStyles}
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </React.Fragment>
  )
}

export default PrimaryCheckbox
