import styles from './styles.module.css'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import determineIfOrderIsFinishable from '../../../../../../global/utils/order/determine-if-order-is-finishable'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'
import { useContext } from 'react'

export interface ITrOrderNumbersProps {
  trOrderNum: string
  addTrOrderNum: string
}

interface IOrderActionsProps {
  openOrderModal: () => void
  isReadOnly: boolean
}

export default function OrderActions({
  openOrderModal,
  isReadOnly,
}: IOrderActionsProps) {
  const { orderData } = useContext(OrderContext)
  const buttonName = determineIfOrderIsFinishable(orderData.status)
    ? 'Finish Order'
    : 'Edit Order'

  return (
    <div className={styles.orderActionsContainer}>
      <div className={styles.additionalActions}>
        <SecondaryButton
          onClick={openOrderModal}
          buttonName={buttonName}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
