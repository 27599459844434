import { IVanPartial } from '../../../app/entities/Van'

export const createVan = (isActive: boolean, name: string) => {
  const body = {
    name,
    active: isActive,
  }
  return {
    url: `${process.env.REACT_APP_ORIGIN}/vans`,
    method: 'POST',
    body,
  }
}
export const updateVan = (body: IVanPartial) => {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/vans`,
    method: 'PUT',
    body,
  }
}
export const setVanToLocation = (vanId: string, locationId: string) => {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/vans/${vanId}`,
    method: 'PUT',
  }
}

export const getImageBys3ObjectKey = (s3ObjectKey: string) => {
  const url = `${process.env.REACT_APP_ORIGIN}/images/byObjectKey?s3ObjectKey=${s3ObjectKey}`
  return {
    url: url,
    method: 'GET',
  }
}

export const getVanByObjectId = (vanObjectId: string) => {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/vans/byObjectid/${vanObjectId}`,
    method: 'GET',
  }
}
