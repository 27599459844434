import { Route } from 'react-router-dom'
import DashboardsMain from '../../dashboards/main/DashboardsMain'
import NavLinkPropsWithElement from '../../global/types/NavLinkPropsWithElement'
import Holidays from '../../holidays/components/main/HolidaysMain'
import ReportsMain from '../../reports/components/main/ReportsMain'
import AppointmentsMain from '../../schedule/components/main/AppointmentsMain'
import UserManagement from '../../user-management/main/UserManagementMain'
import VansMain from '../../vans/main/VansMain'
import WorkOrders from '../../work-orders/components/main/WorkOrdersMain'
import { RequireAuth } from '../components/auth/RequireAuth'
import { TITLES } from '../constants/nav-bar-links'
import { PERMISSIONS } from '../constants/permissions'
import ModalContent from '../../work-orders/components/work-order-detail/components/main/ModalContent'

const linksData: Array<NavLinkPropsWithElement> = [
  {
    to: '/',
    title: TITLES.SCHEDULE,
    element: (
      <RequireAuth>
        <AppointmentsMain />
      </RequireAuth>
    ),
    nestedRoutes: [<Route path=':id' element={<ModalContent />} />],
  },
  {
    to: '/work-orders',
    title: TITLES.WORK_ORDERS,
    element: (
      <RequireAuth>
        <WorkOrders />
      </RequireAuth>
    ),
    nestedRoutes: [<Route path=':id' element={<ModalContent />} />],
  },
  {
    to: '/vans',
    title: TITLES.VANS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_VANS.NAME,
    },
    element: (
      <RequireAuth>
        <VansMain />
      </RequireAuth>
    ),
    nestedRoutes: [
      <Route
        path=':id'
        element={
          <RequireAuth>
            <VansMain />
          </RequireAuth>
        }
      />,
    ],
  },
  {
    to: '/holidays',
    title: TITLES.HOLIDAYS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_HOLIDAYS.NAME,
    },
    element: (
      <RequireAuth>
        <Holidays />
      </RequireAuth>
    ),
  },
  {
    to: '/reports',
    title: TITLES.REPORTS,
    element: (
      <RequireAuth>
        <ReportsMain />
      </RequireAuth>
    ),
    nestedRoutes: [<Route path=':id' element={<ModalContent />} />],
  },
  {
    to: '/dashboards',
    title: TITLES.DASHBOARDS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_DASHBOARDS.NAME,
    },
    element: (
      <RequireAuth>
        <DashboardsMain />
      </RequireAuth>
    ),
  },
  {
    to: '/user-management/users',
    title: TITLES.USER_MANAGEMENT,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_USER_MANAGEMENT.NAME,
    },
    element: (
      <RequireAuth>
        <UserManagement tabType='users' />
      </RequireAuth>
    ),
    nestedRoutes: [<Route path=':id' element={<ModalContent />} />],
  },
]

export default linksData
