const STATUSES = {
  cancelled: 'cancelled',
  cancelled_onsite: 'cancelled_onsite',
  completed: 'completed',
  en_route: 'en_route',
  on_site: 'on_site',
  scheduled: 'scheduled',
  started: 'started',
  unconfirmed: 'unconfirmed',
  quote: 'quote',
}

const SCHEDULED_STATUSES = [STATUSES.scheduled]

export { STATUSES, SCHEDULED_STATUSES }
