import { useContext } from 'react'
import { IPermission } from '../../../../../../app/entities/Permission'
import { PERMISSIONS } from '../../../../global/constants/permissions'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import userHasPermission from '../../../../global/utils/user/has-permission'

const { CATEGORY, ENTRIES } = PERMISSIONS.LOCATION_SETTINGS
function determineIfRightColumnIsVisible(userPermissions: IPermission[]) {
  const permissionNamesForRightColumn = [
    ENTRIES.BLOCKED_OFF_TIMES,
    ENTRIES.SPOKE_LOCATIONS,
    ENTRIES.SERVICED_ZIP_CODES,
    ENTRIES.TECHNICIAN_EMAILS,
    ENTRIES.TIRE_LOCATIONS,
    ENTRIES.WORKDAY_INFO,
  ]

  const { user } = useContext(UserContext)

  const canViewSomeField = permissionNamesForRightColumn.some(({ NAME }) =>
    userHasPermission(user).view(CATEGORY, NAME),
  )
  const canEditSomeField = permissionNamesForRightColumn.some(({ NAME }) =>
    userHasPermission(user).edit(CATEGORY, NAME),
  )
  return canViewSomeField || canEditSomeField
}

export default determineIfRightColumnIsVisible
