import moment from 'moment'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { ILocation } from '../../../../../../../app/entities/Location'
import calculateTimeSlotsForLocation from '../../../../../global/utils/calculateTimeSlotsForLocation'
import styles from './styles.module.css'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from '@mui/material'
import calculateWorkDayMinutes from '../../../../../global/utils/calculate-work-day-minutes'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import userHasPermission from '../../../../../global/utils/user/has-permission'

const { CATEGORY } = PERMISSIONS.LOCATION_SETTINGS

const { WORKDAY_INFO, LOCATION_INFO, INSTALLER_ID, WEBSITE_SCHEDULE_BUFFER } =
  PERMISSIONS.LOCATION_SETTINGS.ENTRIES

interface ILocationFormProps {
  location: Partial<ILocation>
  setLocation: Function
  isNew: boolean
  averageJobTimes: {
    averageDriveTime: number
    averageInstallTime: number
  } | null
}

const MINUTES_STEP = 15

const SCHEDULE_BUFFER_OPTIONS = {
  NONE: 'none',
  SAME_DAY: 'sameDay',
  NEXT_DAY: 'nextDay',
}

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#d70000', // Change this to your desired color
  },
}))
const StyledSelect = styled(Select)(({ theme }) => ({
  transform: 'scale(0.9)',
}))

export function LocationInfo({
  location,
  setLocation,
  isNew,
  averageJobTimes,
}: ILocationFormProps) {
  const { user } = useContext(UserContext)

  let {
    name,
    installerId,
    addressStreet,
    addressCity,
    stateAbbr,
    zipCode,
    workDayFrom,
    lunchFrom,
    websiteScheduleBuffer,
  } = location
  const updateLocationForm = (e: ChangeEvent<HTMLInputElement> | any) => {
    setLocation({ ...location, [e.target.name]: e.target.value })
  }

  let averageTimeValues = <>No data yet.</>

  if (averageJobTimes) {
    const { averageDriveTime, averageInstallTime } = averageJobTimes

    if (averageDriveTime && averageInstallTime) {
      const driveTimeInMinutes = averageDriveTime / 1000 / 60
      const installTimeInMinutes = averageInstallTime / 1000 / 60
      averageTimeValues = (
        <>
          <div>Drive: {driveTimeInMinutes.toFixed()} min</div>
          <div>Install: {installTimeInMinutes.toFixed()} min</div>
        </>
      )
    }
  }

  // jmullet comment: The following function is used to ensure that all
  // of the workDay and lunch values are properly formatted as we
  // are transitioning from free text to time picker components.

  function ensureTimeFormat(time: string) {
    if (time.length === 4) {
      return `0${time}`
    } else if (time === '24:00') {
      // some stores were setting the workDayTo to 24:00
      // which the time picker interprets as 00:00 which is
      // confusing visually.  So we are going to change it to
      // 23:00 which should allow for the same functionality.
      return '23:00'
    }
    return time
  }

  const plannedTimesMenuItems = Array(9)
    .fill(null)
    .map((_, i) => (
      <MenuItem key={i} value={i * MINUTES_STEP}>
        {i * MINUTES_STEP}
      </MenuItem>
    ))

  const { slotStarts, slotsOverlappingLunch } =
    calculateTimeSlotsForLocation(location)

  const TimeSlotDescription = (
    <div className={styles.timeSlotDescriptionContainer}>
      {slotStarts.length ? (
        <>
          Based on the current setup, the time slots shown to customers will be:
          <div className={styles.timeSlotDescription}>
            {slotStarts
              .map((slot) => moment.utc(slot).format('h:mm a'))
              .join(', ')}
          </div>
          {slotsOverlappingLunch.length > 0 && (
            <>
              The following time slots will always show as "unavailable" since
              they overlap with the lunch break:{' '}
              <div className={styles.timeSlotDescription}>
                {slotsOverlappingLunch
                  .map((slot) => moment.utc(slot).format('h:mm a'))
                  .join(', ')}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <b>Invalid selections.</b>
          <br />
          Please ensure that the workday, lunch, and planned times are set
          correctly.
        </>
      )}
    </div>
  )

  const hoursAvailableToBuffer =
    Math.floor(
      calculateWorkDayMinutes(
        location.workDayFrom ?? '',
        location.workDayTo ?? '',
      ) / 60,
    ) - 1
  const bufferHoursOptions = Array.from(
    { length: hoursAvailableToBuffer },
    (_, i) => i + 1,
  )

  function determineBufferSelection(websiteScheduleBuffer: number) {
    if (websiteScheduleBuffer === 0) {
      return SCHEDULE_BUFFER_OPTIONS.NONE
    } else if (websiteScheduleBuffer === 24) {
      return SCHEDULE_BUFFER_OPTIONS.NEXT_DAY
    } else {
      return SCHEDULE_BUFFER_OPTIONS.SAME_DAY
    }
  }
  function determineHours(websiteScheduleBuffer: number = 0) {
    return [0, 24].includes(websiteScheduleBuffer) ? 1 : websiteScheduleBuffer
  }
  function determineIsHoursDisabled(radioSelection: string) {
    return (
      radioSelection === SCHEDULE_BUFFER_OPTIONS.NONE ||
      radioSelection === SCHEDULE_BUFFER_OPTIONS.NEXT_DAY
    )
  }

  const initialSelectedBuffer = determineBufferSelection(
    websiteScheduleBuffer ?? 0,
  )
  const [selectedBufferButton, setSelectedBufferButton] = useState(
    initialSelectedBuffer,
  )
  const [hoursValue, setHoursValue] = useState(
    determineHours(websiteScheduleBuffer),
  )
  const [isHoursDisabled, setIsHoursDisabled] = useState(
    determineIsHoursDisabled(initialSelectedBuffer),
  )
  // dherthoge: need to reset the state that depends on initialSelectedBuffer
  // once it is set
  useEffect(() => {
    setSelectedBufferButton(initialSelectedBuffer)
    setHoursValue(determineHours(websiteScheduleBuffer))
    setIsHoursDisabled(determineIsHoursDisabled(initialSelectedBuffer))
  }, [initialSelectedBuffer])

  function handleBufferChange(e: ChangeEvent<HTMLInputElement>) {
    const newBufferSelection = e.target.value
    setSelectedBufferButton(newBufferSelection)
    setIsHoursDisabled(determineIsHoursDisabled(newBufferSelection))

    let newWebsiteScheduleBuffer = 0
    if (e.target.value === SCHEDULE_BUFFER_OPTIONS.SAME_DAY) {
      newWebsiteScheduleBuffer = hoursValue
    } else if (e.target.value === SCHEDULE_BUFFER_OPTIONS.NEXT_DAY) {
      newWebsiteScheduleBuffer = 24
    }

    setLocation({
      ...location,
      websiteScheduleBuffer: newWebsiteScheduleBuffer,
    })
  }

  function handleHoursChange(event: SelectChangeEvent<unknown>) {
    const newWebsiteScheduleBuffer =
      typeof event.target.value === 'string'
        ? parseInt(event.target.value)
        : (event.target.value as number)

    setBuffer(newWebsiteScheduleBuffer)
  }
  function setBuffer(hours: number) {
    setHoursValue(hours)
    setLocation({
      ...location,
      websiteScheduleBuffer: hours,
    })
  }

  function onTimePickerAccept() {
    if (
      (websiteScheduleBuffer ?? 0) >
      bufferHoursOptions[bufferHoursOptions.length - 1]
    ) {
      setBuffer(bufferHoursOptions[bufferHoursOptions.length - 1])
    }
  }

  return (
    <div className={styles.locationFormContainer}>
      <div className={styles.locationFormTitle}>Workday</div>
      <div className={styles.twoColumnsContainer}>
        {
          // ['workDayFrom', 'workDayTo', 'lunchFrom', 'lunchTo', 'plannedServiceTime', 'plannedTravelTime']
          [
            {
              name: 'workDayFrom',
              label: 'Workday From',
            },
            {
              name: 'workDayTo',
              label: 'Workday To',
              minTime: workDayFrom,
              maxTime: '23:00',
            },
            {
              name: 'lunchFrom',
              label: 'Lunch From',
            },
            {
              name: 'lunchTo',
              label: 'Lunch To',
              minTime: lunchFrom,
            },
          ].map((field) => (
            <TextField
              key={field.name}
              type='time'
              label={field.label}
              name={field.name}
              value={ensureTimeFormat(
                location[field.name as keyof ILocation] as string,
              )}
              inputProps={{
                min: field.minTime,
                max: field.maxTime,
                step: 900, // 15 minutes
              }}
              disabled={
                !userHasPermission(user).edit(CATEGORY, WORKDAY_INFO.NAME)
              }
              onChange={updateLocationForm}
            />
          ))
        }
        {[
          {
            name: 'plannedServiceTime',
            label: 'Planned Install Time (min)',
          },
          {
            name: 'plannedTravelTime',
            label: 'Planned Drive Time (min)',
          },
        ].map((field) => (
          <TextField
            key={field.name}
            select
            label={field.label}
            name={field.name}
            value={(location[field.name as keyof ILocation] ?? 0) as number}
            onChange={updateLocationForm}
            disabled={
              !userHasPermission(user).edit(CATEGORY, WORKDAY_INFO.NAME)
            }
          >
            {plannedTimesMenuItems}
          </TextField>
        ))}
        {!isNew && (
          <>
            <div className={styles.averageTimeDescription}>
              Avg job times over the last 30 days:
            </div>
            <div className={styles.averageTimeValues}>{averageTimeValues}</div>
          </>
        )}
      </div>
      {TimeSlotDescription}
      <div className={styles.locationFormSubtitle}>Website Schedule Buffer</div>
      <div className={styles.locationFormDescription}>
        This buffer will not affect scheduling when creating on order on this
        site here. Instead, this buffer will affect customers when they are
        booking through the Tire Rack website.
      </div>
      <FormControl
        component='fieldset'
        disabled={
          !userHasPermission(user).edit(CATEGORY, WEBSITE_SCHEDULE_BUFFER.NAME)
        }
      >
        <RadioGroup value={selectedBufferButton} onChange={handleBufferChange}>
          <FormControlLabel
            value={SCHEDULE_BUFFER_OPTIONS.NONE}
            control={<StyledRadio className={styles.radioButton} />}
            label='None'
          />
          <Box className={styles.sameDayRadioButton}>
            <FormControlLabel
              value={SCHEDULE_BUFFER_OPTIONS.SAME_DAY}
              control={<StyledRadio />}
              label='Same Day'
            />
            <div>
              <StyledSelect
                value={hoursValue}
                disabled={
                  isHoursDisabled ||
                  !userHasPermission(user).edit(
                    CATEGORY,
                    WEBSITE_SCHEDULE_BUFFER.NAME,
                  )
                }
                onChange={handleHoursChange}
              >
                {bufferHoursOptions.map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </StyledSelect>
              <FormHelperText>Hours</FormHelperText>
            </div>
          </Box>
          <FormControlLabel
            value={SCHEDULE_BUFFER_OPTIONS.NEXT_DAY}
            control={<StyledRadio />}
            label='Next Day'
          />
        </RadioGroup>
      </FormControl>
      <div className={styles.locationFormTitle}>Location</div>
      <TextField
        onChange={updateLocationForm}
        name='name'
        value={name}
        className={styles.wideTextField}
        label='Name'
        disabled={!userHasPermission(user).edit(CATEGORY, LOCATION_INFO.NAME)}
      />
      <TextField
        onChange={updateLocationForm}
        name='addressStreet'
        value={addressStreet}
        className={styles.wideTextField}
        label='Street'
        disabled={!userHasPermission(user).edit(CATEGORY, LOCATION_INFO.NAME)}
      />
      <TextField
        onChange={updateLocationForm}
        name='addressCity'
        value={addressCity}
        className={styles.wideTextField}
        label='City'
        disabled={!userHasPermission(user).edit(CATEGORY, LOCATION_INFO.NAME)}
      />
      <div className={styles.doubleFieldContainer}>
        <TextField
          onChange={updateLocationForm}
          name='stateAbbr'
          value={stateAbbr}
          className={styles.narrowTextField}
          label='State Abbr'
          disabled={!userHasPermission(user).edit(CATEGORY, LOCATION_INFO.NAME)}
        />
        <TextField
          onChange={updateLocationForm}
          name='zipCode'
          value={zipCode}
          className={styles.narrowTextField}
          label='Zip'
          disabled={!userHasPermission(user).edit(CATEGORY, LOCATION_INFO.NAME)}
        />
      </div>
      <TextField
        onChange={updateLocationForm}
        name='installerId'
        value={installerId}
        className={styles.wideTextField}
        label='Installer ID'
        disabled={!userHasPermission(user).edit(CATEGORY, INSTALLER_ID.NAME)}
      />
    </div>
  )
}
