import { IDaySetting } from '../../../../../app/entities/DaySetting'
import { ILocation } from '../../../../../app/entities/Location'
import { ITabulationArea } from '../../../../../app/entities/TabulationArea'
import { IWeekCalendar } from '../../../../../app/entities/WeekCalendar'

export const getLocationById = (locationId: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}`,
  }
}
export const updateLocation = (locationData: any) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/locations`,
    body: locationData,
  }
}
export const createLocation = (
  location: Partial<ILocation>,
  weekCalendar: Partial<IWeekCalendar>,
) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/locations`,
    body: {
      location,
      weekCalendar,
    },
  }
}

export const updateLocationTabulationAreas = (
  locationObjectId: string,
  tabulationAreas: ITabulationArea[],
) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationObjectId}/tabulation-areas`,
    body: tabulationAreas,
  }
}

export const updateLocationDaySettings = (
  locationObjectId: string,
  daySettings: Partial<IDaySetting>[],
) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationObjectId}/day-settings`,
    body: daySettings,
  }
}

export const createZoneDefinition = (
  zoneDef: any,
  locationId: string | undefined,
) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/zone-definitions`,
    body: zoneDef,
  }
}
export const updateWeekCalendar = (weekCalendar: Partial<IWeekCalendar>) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/week_calendars`,
    body: weekCalendar,
  }
}
export const setTabulationArea = (
  locationId: string | undefined,
  tabArea: any,
) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/tabulation-area`,
    body: tabArea,
  }
}
export const createTabulationArea = (
  locationId: string | undefined,
  tabArea: any,
) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/tabulation-area`,
    body: tabArea,
  }
}

export const uploadZipCSV = (
  locationId: string | undefined,
  csvFile: FormData,
) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/bulk-tabulation-areas`,
    body: csvFile,
  }
}

export function getAverageJobTimes(
  locationObjectId?: string,
  daysAgo: number = 30,
) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationObjectId}/average-job-times?daysAgo=${daysAgo}`,
  }
}

export function getIsSpokeLocationValid(spokeLocationName: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/is-spoke-location-valid/${spokeLocationName}`,
  }
}
