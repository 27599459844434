import { MenuItem, TextField } from '@mui/material'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import PrimaryCheckbox from '../../../../../global/components/checkbox/Checkbox'
import styles from './styles.module.css'
import { SCHEDULER_MODES } from '../../../../../global/constants/scheduler'
import {
  ORDER_SOURCES_ARRAY,
  TIRE_SOURCES,
  TIRE_SOURCES_ARRAY,
} from '../../../../../global/constants/order'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'

interface IOrderDetailsProps {
  setIsForwardDisabled: Function
  isRework: boolean
  mode: string
  setIsRework?: Function
  chosenAddress?: google.maps.GeocoderResult | null
}

function OrderDetails({
  setIsForwardDisabled,
  isRework,
  setIsRework,
  mode,
  chosenAddress,
}: IOrderDetailsProps) {
  const { orderData, setOrderData } = useContext(OrderContext)
  const {
    installationPoint,
    location,
    salesRepresentativeNumber,
    orderSource,
    tireLocation,
    tireSource,
  } = orderData

  const [tireLocationsList, setTireLocationsList] = useState<string[]>([])
  function getAbbreviationFromString(str: string): string {
    return str
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()
  }

  async function getTireLocationsFromLocationId() {
    if (location) {
      let locations: string[]

      if (location.tireLocations) {
        locations = location.tireLocations.split(',')
      } else {
        locations = [location.name]
      }

      setTireLocationsList(locations)
    }
  }

  useEffect(() => {
    getTireLocationsFromLocationId()
  }, [chosenAddress, installationPoint, location])

  function updateOrderDetailsTextInput(e: ChangeEvent<HTMLInputElement>) {
    let { value, name } = e.target

    if (name === 'salesRepresentativeNumber') {
      value = value.replace(/\D+/g, '')
      // this is a hack to set the order details to a specific set of values quickly for devs
      if (value === '46290') {
        setOrderData({
          note: 'Gate code: 1234',
          orderSource: 'BOPIS',
          salesRepresentativeNumber: value,
          tireSource: 'Customer Has Tires',
        })
        return
      }
    }

    if (name === 'tireSource' && value === TIRE_SOURCES.customer) {
      setOrderData({
        [name]: value,
        tireLocation: getAbbreviationFromString(TIRE_SOURCES.customer),
      })
      return
    }

    setOrderData({
      [name]: value,
    })
  }

  function updateNotes({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const note = value

    updateOrderDetailsTextInput({
      target: { value: note, name: 'note' },
    } as EventTarget & ChangeEvent<HTMLInputElement>)
  }

  useEffect(() => {
    const isCustomerHasTires = tireSource === TIRE_SOURCES.customer

    // If tireSource === "Customer Has Tires", do not require 'tireLocation' field
    // Otherwise, include 'tireLocation' in the validation
    const allRequiredFieldsFilled = isCustomerHasTires
      ? salesRepresentativeNumber && tireSource && orderSource
      : salesRepresentativeNumber && tireSource && orderSource && tireLocation

    setIsForwardDisabled(!allRequiredFieldsFilled)
  }, [salesRepresentativeNumber, tireSource, orderSource, tireLocation])

  return (
    <div className={styles.orderDetailsContainer}>
      <div className={`${styles.orderDetailsTitle} font--bold`}></div>
      <div className={styles.orderDetailsForm}>
        {mode !== SCHEDULER_MODES.EDIT && (
          <div className={styles.checkboxContainer}>
            <PrimaryCheckbox
              checked={isRework}
              onChange={(_, isChecked) => setIsRework && setIsRework(isChecked)}
            />
            <span className='font--bold'>Is Rework?</span>
          </div>
        )}
        <TextField
          className={styles.textfieldStyles}
          value={salesRepresentativeNumber}
          type='text'
          label='Salesperson ID #'
          name='salesRepresentativeNumber'
          onChange={updateOrderDetailsTextInput}
        />
        <div className={styles.dropDownContainer}>
          <div className={styles.orderDetailsText}>
            Where did the product come from?
          </div>
          <TextField
            className={styles.dropDownSelect}
            InputLabelProps={{ shrink: Boolean(tireSource) }}
            label='Tire Source'
            select
            name='tireSource'
            onChange={updateOrderDetailsTextInput}
            value={tireSource}
          >
            {TIRE_SOURCES_ARRAY.map((option) => (
              <MenuItem
                key={option}
                value={option}
                className={styles.dropDownOption}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={styles.dropDownContainer}>
          <div className={styles.orderDetailsText}>
            How did the customer contact us?
          </div>
          <TextField
            className={styles.dropDownSelect}
            InputLabelProps={{ shrink: Boolean(orderSource) }}
            label='Order Source'
            select
            name='orderSource'
            onChange={updateOrderDetailsTextInput}
            value={orderSource}
          >
            {ORDER_SOURCES_ARRAY.map((option) => (
              <MenuItem
                key={option}
                value={option}
                className={styles.dropDownOption}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className={styles.dropDownContainer}>
          <div className={styles.orderDetailsText}>
            Where will the tech pick up the tires?
          </div>
          <TextField
            className={styles.dropDownSelect}
            InputLabelProps={{ shrink: Boolean(tireLocation) }}
            label='Tire Location'
            select
            name='tireLocation'
            disabled={tireSource === TIRE_SOURCES.customer}
            onChange={updateOrderDetailsTextInput}
            value={tireLocation}
          >
            {tireLocationsList.map((option) => (
              <MenuItem
                key={option}
                value={option}
                className={styles.dropDownOption}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {mode !== SCHEDULER_MODES.EDIT && (
          <>
            <TextField
              className={styles.textfieldStyles}
              value={orderData.note}
              type='text'
              label='Notes for tech'
              helperText='Example: gate codes, notes, etc'
              name='notesForTech'
              rows={4}
              multiline
              onChange={updateNotes}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default OrderDetails
