import { Dispatch, SetStateAction } from 'react'
import { IVanPartial } from '../../../../../../app/entities/Van'
import styles from './styles.module.css'
import VanFormComponent from '../../new-van-modal/VanFormComponent'
import VirTable from './vir-table/VirTable'
import { VIR_FREQUENCY } from '../../../../global/constants/virs'

interface IEditVanFormComponentProps {
  editVanFormData: IVanPartial
  setEditFormData: Dispatch<SetStateAction<IVanPartial | null>>
  setSelectedLocation: Dispatch<SetStateAction<string>>
  selectedLocation: string
}

function EditVanContentContainer({
  editVanFormData,
  setEditFormData,
  setSelectedLocation,
  selectedLocation,
}: IEditVanFormComponentProps) {
  return (
    <div className={styles.contentContainer}>
      <VanFormComponent
        setSelectedLocation={setSelectedLocation}
        vanFormData={editVanFormData}
        setVanFormData={setEditFormData}
        selectedLocation={selectedLocation}
        isReadOnly={true}
      />
      <div className={styles.tableContainer}>
        <div>
          <VirTable
            frequency={VIR_FREQUENCY.DAILY}
            vanObjectId={editVanFormData.objectId!}
          />
        </div>
        <div>
          <VirTable
            frequency={VIR_FREQUENCY.WEEKLY}
            vanObjectId={editVanFormData.objectId!}
          />
        </div>
      </div>
    </div>
  )
}

export default EditVanContentContainer
