import { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'
import { IOrderVehicle } from '../../../../../../../../app/entities/OrderVehicle'
import Table from '../../../../../../global/components/table/Table'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import { fetchData } from '../../../../../../global/utils/fetch'
import { getOrderVehiclesById } from '../../../../api'
import { SCHEDULER_STEPS } from '../../../../../../global/constants/scheduler'
import determineIfOrderIsEditable from '../../../../../../global/utils/order/determine-if-order-is-editable'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

interface IWorkOrderVehiclesTableProps {
  isReadOnly: boolean
  openOrderModal: () => void
}

export default function WorkOrderVehiclesTable({
  isReadOnly,
  openOrderModal,
}: IWorkOrderVehiclesTableProps) {
  const { orderData } = useContext(OrderContext)
  const orderObjectId = orderData.objectId
  const isOrderEditable =
    !isReadOnly && determineIfOrderIsEditable(orderData.status)

  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
  })
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    async function fetchTableData() {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))
      const data = await fetchData<IOrderVehicle[]>(
        getOrderVehiclesById(orderObjectId),
      )

      setTableState((prevState: any) => ({
        ...prevState,
        data: data,
        isLoading: false,
      }))
    }
    fetchTableData()
  }, [orderObjectId])

  const columns: Column[] = [
    {
      Header: 'Vehicle ID',
      accessor: 'orderVehicleId',
    },
    {
      Header: 'Make & Model',
      Cell: ({ row: { original } }) => {
        const { customerVehicle } = original as IOrderVehicle

        return (
          <div>
            <div>{customerVehicle?.brand?.name}</div>
            <div>{customerVehicle?.model}</div>
            {customerVehicle?.version}
            <div>{customerVehicle?.year}</div>
          </div>
        )
      },
    },
    {
      Header: 'Tire Count',
      Cell: ({ row: { original } }) => {
        const { tiresCombination } = original as IOrderVehicle

        return (
          <div>
            <div>Front: {tiresCombination?.frontCount}</div>
            <div>Rear: {tiresCombination?.rearCount}</div>
          </div>
        )
      },
    },
    {
      Header: 'Front Specs',
      Cell: ({ row: { original } }) => {
        const { tiresCombination } = original as IOrderVehicle
        const frontTire = tiresCombination ? tiresCombination.frontTire : null

        return (
          <div>
            <div>Width: {frontTire?.width}</div>
            <div>Aspect Ratio: {frontTire?.aspectRatio}</div>
            <div>Diameter: {frontTire?.diameter}</div>
            <div>PSI: {frontTire?.psi}</div>
          </div>
        )
      },
    },
    {
      Header: 'Rear Specs',
      Cell: ({ row: { original } }) => {
        const { tiresCombination } = original as IOrderVehicle
        const rearTire = tiresCombination ? tiresCombination.rearTire : null

        return (
          <div>
            <div>Width: {rearTire?.width}</div>
            <div>Aspect Ratio: {rearTire?.aspectRatio}</div>
            <div>Diameter: {rearTire?.diameter}</div>
            <div>PSI: {rearTire?.psi}</div>
          </div>
        )
      },
    },
    {
      Header: 'Torque',
      Cell: ({ row: { original } }) => {
        const { tiresCombination } = original as IOrderVehicle as any

        return (
          tiresCombination?.rearTire?.torque ||
          tiresCombination?.frontTire?.torque ||
          'N/A'
        )
      },
    },
    {
      Header: 'Tire Make & Model',
      Cell: ({ row: { original } }) => {
        const { tiresCombination } = original as IOrderVehicle

        const { rearTire, frontTire } = tiresCombination || {}
        const tire = rearTire || frontTire

        return (
          <div>
            <div>{tire?.manufacturer || 'N/A'}</div>
            <div>{tire?.model || 'N/A'}</div>
          </div>
        )
      },
    },
    {
      Header: 'Mileage',
      accessor: 'mileage',
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <TableTopper isOpen={isOpen} onToggleClick={() => setIsOpen(!isOpen)}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Work Order Vehicles Information</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data}
        isLoading={tableState.isLoading}
        onRowClick={isOrderEditable ? openOrderModal : undefined}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}
