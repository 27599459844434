import { useAuthenticator } from '@aws-amplify/ui-react'
import { useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import { IVan, IVanPartial } from '../../../../app/entities/Van'
import Table from '../../global/components/table/Table'
import BasicFilter from '../../global/components/table/helpers/BasicFilter'
import NoFilter from '../../global/components/table/helpers/NoFilter'
import { handleColumnFilterUpdate } from '../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../global/components/table/helpers/retrieveData'
import { ILocationFilters } from '../../work-orders/components/main/WorkOrdersMain'
import { IRefreshCounterProps } from '../main/VansMain'
import EditVanModal from '../modal/edit-van-modal/main/EditVanModal'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { fetchData } from '../../global/utils/fetch'
import { getVanByObjectId } from '../api'
import createSearchParamsString from '../../global/utils/createSearchParamsString'
import { convertIsoToYMD } from '../../global/utils/dates'

interface IVansTableProps {
  locationFilters: ILocationFilters
  refresh: IRefreshCounterProps
}

function VansTable(props: IVansTableProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { id } = useParams()
  const { locationFilter } = props.locationFilters
  const { refreshCounter, setRefreshCounter } = props.refresh
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState<IVanPartial | null>(
    null,
  )
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    pageSize: 100,
    offSet: 0,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const endpoint = `${process.env.REACT_APP_ORIGIN}/vans?`

  const getSingleVanById = async (vanObjectId: string) => {
    const res = await fetchData<IVan>(getVanByObjectId(vanObjectId))
    return res
  }
  useEffect(() => {
    const fetchSingleVanAndOpenModal = async () => {
      if (id) {
        try {
          const van = await getSingleVanById(id)
          setSelectedRowData(van)
          // Only call handleOpen if the modal isn't already open to prevent unnecessary re-navigation
          if (!isModalOpen) {
            handleOpen(id) // Pass true to indicate preserving the query
          }
        } catch (error) {
          console.error('Failed to fetch van details:', error)
        }
      }
    }
    fetchSingleVanAndOpenModal()
  }, [id, isModalOpen, searchParams]) // Depend on isModalOpen to prevent redundant calls

  const handleOpen = async (id: string) => {
    setIsModalOpen(true)
    const queryStringParams = await createSearchParamsString(searchParams)
    const url = `/vans/${id}` + queryStringParams // Reapply it to the new URL
    navigate(url)
  }

  const handleClosed = () => {
    navigate('/vans')
    setIsModalOpen(false)
  }

  useEffect(() => {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      locationId: locationFilter,
      columnFilters,
    })
  }, [
    locationFilter,
    columnFilters,
    tableState.pageSize,
    tableState.offSet,
    refreshCounter,
  ])

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  const columns: Column<any>[] = [
    {
      Header: 'Van Name',
      accessor: 'name',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ name: val }),
      }),
    },
    {
      Header: 'Defects',
      accessor: (d: any) => {
        return d?.vir[0]?.defects ? 'Yes' : 'No'
      },
      Filter: NoFilter,
    },
    {
      Header: 'Last Daily',
      accessor: (d: any) => {
        const dateString = d.lastDvir
        const cleanedTime = convertIsoToYMD(dateString)
        return dateString && cleanedTime
      },
      Filter: NoFilter,
    },
    {
      Header: 'Last Weekly',
      accessor: (d: any) => {
        const dateString = d.lastWvir
        const cleanedTime = convertIsoToYMD(dateString)
        return dateString && cleanedTime
      },
      Filter: NoFilter,
    },
    {
      Header: 'Active Status',
      accessor: (data: any) => {
        const { active } = data
        return active ? 'Active' : 'Inactive'
      },
      Filter: NoFilter,
    },
    {
      Header: 'Location',
      accessor: (d: any) => {
        return d?.location?.name
      },
      Filter: BasicFilter({
        onEnter: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
      }),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <EditVanModal
        isOpen={isModalOpen}
        handleClosed={handleClosed}
        refresh={{ refreshCounter, setRefreshCounter }}
        selectedRowData={selectedRowData}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={(row: Row) => {
          const original: any = row.original
          setSelectedRowData(original)
          handleOpen(original.objectId)
        }}
        isPaginated
        pagination={{ setTableState, tableState }}
      />
    </div>
  )
}

export default VansTable
