import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { IRoute } from '../../../../app/entities/Route'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import LocationDropDown from '../../global/components/location-drop-down/LocationDropDown'
import { fetchData } from '../../global/utils/fetch'
import {
  associateBumpedOrdersToRouteArea,
  runRouteOptimizationForLocation,
} from '../api'
import styles from './styles.module.css'

export default function RouteOptimizationCorrector() {
  const [form, setForm] = useState({
    date: '',
    locationObjectId: '',
  })

  const handleAssociateBumpedOrders = async () => {
    const { date, locationObjectId } = form
    if (!date || !locationObjectId)
      return alert('Please select a date and location')

    try {
      const res = await fetchData<any>(associateBumpedOrdersToRouteArea(form))
      console.log(res)
      alert(
        `Associated bumped orders.\nCount: ${res.ordersCount}\nMessage: ${res.message}`,
      )
    } catch (error) {
      console.error(error)
      alert('Error associating bumped orders')
    }
  }

  const handleRunROForLocation = async () => {
    const { date, locationObjectId } = form
    if (!date || !locationObjectId)
      return alert('Please select a date and location')

    try {
      const res = await fetchData<IRoute[]>(
        runRouteOptimizationForLocation({
          locationId: locationObjectId,
          date: new Date(date).setUTCHours(0, 0, 0, 0).valueOf(),
        }),
      )
      console.log(res)
      alert('Ran RO for location')
    } catch (error) {
      console.error(error)
      alert('Error running RO for location')
    }
  }

  return (
    <div>
      <h2>Route Optimization Corrector</h2>
      <div className={styles.inputsContainer}>
        <LocationDropDown
          onLocationChange={(locationObjectId: any) =>
            setForm({ ...form, locationObjectId })
          }
        />
        <TextField
          type='date'
          label='Date'
          name='date'
          value={form.date}
          onChange={(e) => setForm({ ...form, date: e.target.value })}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <PrimaryButton
          buttonName='Associate bumped orders'
          onClick={handleAssociateBumpedOrders}
        />
        <PrimaryButton
          buttonName='Run RO for location'
          onClick={handleRunROForLocation}
        />
      </div>
    </div>
  )
}
