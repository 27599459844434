import {
  useTable,
  useSortBy,
  Column,
  useFilters,
  Row,
  usePagination,
} from 'react-table'
import styles from './styles.module.css'
import PaginationComponent from './helpers/pagination/Pagination'
import React from 'react'

interface TableDataState {
  data: Array<Row>
  isLoading?: boolean
}

function TableIndicator({ data, isLoading }: TableDataState) {
  let expression = ''

  if (isLoading) {
    expression = 'Loading...'
  } else if (!data.length) {
    expression = 'No results found.'
  }

  return (
    <tr>
      <td colSpan={1000} className={`${styles.tableLoading}`}>
        {expression}
      </td>
    </tr>
  )
}

interface TableProps extends TableDataState {
  columns: Column[]
  isOpen?: boolean
  onRowClick?: Function
  isDisplayMode?: boolean
  customRow?: null | JSX.Element
  isPaginated?: boolean
  pagination?: any
  style?: React.CSSProperties
  disableSortBy?: boolean
}

function Table({
  columns,
  data,
  isLoading,
  isOpen,
  onRowClick,
  isDisplayMode = false,
  customRow,
  isPaginated = false,
  pagination,
  style,
  disableSortBy,
}: TableProps) {
  // const { setTableState, tableState } = pagination
  const hooks: any[] = []

  if (!isDisplayMode) {
    hooks.push(useFilters)
  }

  const handlePrevPage = () => {
    if (
      isPaginated &&
      pagination?.tableState.offSet - pagination?.tableState.pageSize >= 0
    ) {
      pagination?.setTableState((prevState: any) => ({
        ...prevState,
        offSet: prevState.offSet - prevState.pageSize,
      }))
    }
  }

  const handleNextPage = () => {
    if (
      isPaginated &&
      pagination?.tableState.offSet + pagination?.tableState.pageSize <
        pagination?.tableState.count
    ) {
      pagination?.setTableState((prevState: any) => ({
        ...prevState,
        offSet: prevState.offSet + prevState.pageSize,
      }))
    }
  }

  const handleRowsPerPageChange = (newPageSize: number) => {
    if (isPaginated) {
      pagination?.setTableState((prevState: any) => ({
        ...prevState,
        pageSize: newPageSize,
        offSet: 0,
      }))
    }
  }

  if (!disableSortBy) {
    // must be after `useFilters` or it becomes very frustrated.
    hooks.push(useSortBy)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, ...hooks, usePagination)

  const seeTable = typeof isOpen === 'undefined' ? true : isOpen

  const shouldShowTableIndicator = isLoading || (!data.length && !customRow)

  const onRowClickWrapper = onRowClick || (() => {})

  const tableRows = rows.slice(0, 100).map((row, key) => {
    prepareRow(row)
    return (
      <tr
        className={onRowClick ? styles.bodyRow : styles.bodyRowNoHover}
        {...row.getRowProps()}
        key={key}
        onClick={() => onRowClickWrapper(row)}
      >
        {row.cells.map((cell: any) => {
          const width = cell.column.width
          return (
            <td
              // style={{ width: cell.column.Header.includes("ID") ? "40px !important" : "auto" }}
              className={styles.bodyRowCell}
              {...cell.getCellProps()}
              width={width}
            >
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
    )
  })

  if (customRow) {
    tableRows.unshift(customRow)
  }

  return (
    <table
      hidden={!seeTable}
      className={`${styles.table}`}
      {...getTableProps()}
      style={style || {}}
    >
      <thead className='ReactTable rt-thead'>
        {headerGroups.map((headerGroup, idx) => (
          <tr
            className={`${styles.columnHeaderRow}`}
            {...headerGroup.getHeaderGroupProps()}
            key={idx}
          >
            {headerGroup.headers.map((column: any, idx) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th
                className={`${styles.tableHeader} ${
                  isDisplayMode ? styles.displayMode : ''
                }`}
                key={idx}
              >
                <div className={styles.thFlexWrapper}>
                  <div
                    className={styles.columnHeader}
                    {...column.getHeaderProps(
                      disableSortBy ? {} : column.getSortByToggleProps(),
                    )}
                  >
                    <div className={`${styles.columnHeaderTitle} font--bold`}>
                      {column.render('Header')}
                    </div>
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </div>
                  <div className={styles.filterContainer}>
                    {column && column.canFilter
                      ? column.render('Filter')
                      : null}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {shouldShowTableIndicator ? (
          <TableIndicator data={data} isLoading={isLoading} />
        ) : (
          tableRows
        )}
      </tbody>
      {isPaginated && (
        <PaginationComponent
          totalRows={pagination?.tableState.count}
          rowsPerPage={pagination?.tableState.pageSize}
          currentPage={
            Math.ceil(
              pagination?.tableState.offSet / pagination?.tableState.pageSize,
            ) + 1
          }
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </table>
  )
}

export default Table
