import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Column } from 'react-table'
import { IRouteInstruction } from '../../../../../app/entities/RouteInstruction'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import Table from '../../../global/components/table/Table'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import { OrderContext } from '../../../global/context/order-context/OrderContext'
import WorkOrderDetailModal from '../work-order-detail/components/main/WorkOrderDetailModal'
import canUserViewAllLocations from '../../../global/utils/user/can-user-view-all-locations'
import removeDoubledCommas from '../../../global/utils/remove-doubled-commas'

interface IWorkOrdersTableProps {
  locationFilter: string
}

function WorkOrdersTable({ locationFilter }: IWorkOrdersTableProps) {
  const { id } = useParams()
  const navigate = useNavigate()
  const { user } = useContext(UserContext)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    count: 0,
    pageSize: 100,
    offSet: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})

  const { queryOrderData, clearOrderData } = useContext(OrderContext)

  async function handleOpen(id: string) {
    const url = `/work-orders/${id}`
    navigate(url)
    setModalOpen(true)
    await queryOrderData(id)
  }

  function handleClosed() {
    setModalOpen(false)
    navigate('/work-orders')
    clearOrderData()
    retrieveTableData()
  }
  function onRecordClickHandler(row: any) {
    const {
      original: { objectId },
    } = row
    handleOpen(objectId)
  }

  const endpoint = `${process.env.REACT_APP_ORIGIN}/orders/getAllOrders?`

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  useEffect(() => {
    if (id && !modalOpen) {
      handleOpen(id)
    }
  }, [id])

  async function retrieveTableData() {
    // Some roles should only see orders for their location; we need to check
    // the user's role and only make the call if the user can view all
    // locations or if a location filter is applied (automatically)
    // const doesUserHaveViewableLocations =
    //   (await canUserViewAllLocations(user)) || locationFilter
    // if (!doesUserHaveViewableLocations) return

    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      sortBy: '`workOrderId` DESC',
      selectedLocationId: locationFilter,
      columnFilters,
    })
  }

  useEffect(() => {
    retrieveTableData()
  }, [locationFilter, tableState.pageSize, tableState.offSet, user])

  const columns: Column[] = [
    {
      Header: 'Order ID',
      accessor: 'workOrderId',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ workOrderId: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Location',
      accessor: 'location.name',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ status: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Service Date',
      accessor: (row: any) => {
        const { routeInstructions } = row

        if (!routeInstructions || !routeInstructions.length) return '-'

        const [routeInstruction]: IRouteInstruction[] = routeInstructions

        return moment.utc(routeInstruction.startTime).format('MM/DD/YY')
      },
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            routeInstructions: {
              route: {
                dayFormatted: val,
              },
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Service Time',
      accessor: (row: any) => {
        const { routeInstructions } = row

        if (!routeInstructions || !routeInstructions.length) return '-'

        const [routeInstruction]: IRouteInstruction[] = routeInstructions

        return moment.utc(routeInstruction.startTime).format('hh:mm A')
      },
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            routeInstructions: {
              startTimeGenerated: val,
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'First Name',
      accessor: 'customer.firstName',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { firstName: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Last Name',
      accessor: 'customer.lastName',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { lastName: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Customer Email',
      accessor: 'customer.email',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { email: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Customer Phone',
      accessor: 'customer.trimmedPhoneNumber',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { trimmedPhoneNumber: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Installation Address',
      accessor: (row: any) =>
        removeDoubledCommas(row.installationPoint.fullAddress),
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installationPoint: { fullAddress: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Order Source',
      accessor: 'orderSource',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ orderSource: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Tire Source',
      accessor: 'tireSource',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tireSource: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Salesperson ID #',
      accessor: 'salesRepresentativeNumber',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ salesRepresentativeNumber: val }),
        onEnter: retrieveTableData,
      }),
    },
  ]

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <WorkOrderDetailModal handleClosed={handleClosed} isOpen={modalOpen} />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={onRecordClickHandler}
        isPaginated
        pagination={{ setTableState, tableState }}
      />
    </div>
  )
}

export default WorkOrdersTable
