import { Column, Row } from 'react-table'
import { IDaySetting } from '../../../../../../../app/entities/DaySetting'
import Table from '../../../../../global/components/table/Table'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import styles from './styles.module.css'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { useState } from 'react'
import { MenuItem, TextField } from '@mui/material'
import { ILocation } from '../../../../../../../app/entities/Location'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import moment from 'moment'
import { TimePicker } from '../../../../../global/components/input-fields/time-picker/TimePicker'

interface IDaySettingsManagerProps {
  daySettings: Partial<IDaySetting>[]
  setDaySettings: Function
  location: Partial<ILocation>
  isReadOnly: boolean
}

const MINUTES_STEP = 15

export default function DaySettingsManager({
  daySettings,
  setDaySettings,
  location,
  isReadOnly,
}: IDaySettingsManagerProps) {
  const [newDaySetting, setNewDaySetting] = useState<Partial<IDaySetting>>({
    day: undefined,
    startTime: undefined,
    endTime: undefined,
    reason: undefined,
    created: Date.now(),
  })

  const [canSeeNewDaySettingInputs, setCanSeeNewDaySettingInputs] =
    useState(false)

  let createNewDaySettingButtonDisabled = true
  let createNewDaySettingButtonLabel = ''

  if (!newDaySetting.day) {
    createNewDaySettingButtonLabel = 'Choose day'
  } else if (!newDaySetting.startTime) {
    createNewDaySettingButtonLabel = 'Choose start time'
  } else if (!newDaySetting.endTime) {
    createNewDaySettingButtonLabel = 'Choose end time'
  } else if (newDaySetting.endTime <= newDaySetting.startTime) {
    createNewDaySettingButtonLabel = 'End time must be after start time'
  } else if (!newDaySetting.reason) {
    createNewDaySettingButtonLabel = 'Input reason'
  } else {
    createNewDaySettingButtonDisabled = false
    createNewDaySettingButtonLabel = 'Add'
  }

  let hoursOptions: JSX.Element[] = []

  if (location.objectId) {
    const { workDayFrom = '00:00', workDayTo = '00:00' } = location
    const [workDayFromHour, workDayToHour] = [workDayFrom, workDayTo]
      .map((time) => (time || '').split(':')[0])
      .map(Number)

    //ensure array is not negative length
    hoursOptions = Array(Math.max(0, workDayToHour - workDayFromHour + 1))
      .fill(undefined)
      .map((_, index) => {
        const hours = workDayFromHour + index
        return hours < 10 ? `0${hours}:00` : `${hours}:00`
      })
      .map((hour) => (
        <MenuItem
          key={hour}
          value={hour}
          disabled={isReadOnly}
          className={styles.dropDownOption}
        >
          {hour}
        </MenuItem>
      ))
  }

  const sortedDaySettings = daySettings.sort(
    (a: Partial<IDaySetting>, b: Partial<IDaySetting>) =>
      (b.created || 0) - (a.created || 0),
  )

  const handleNewDaySettingCreation = () => {
    setDaySettings([newDaySetting, ...sortedDaySettings])
    setNewDaySetting({
      day: undefined,
      startTime: undefined,
      endTime: undefined,
      reason: undefined,
      created: Date.now(),
    })
  }

  const columns = [
    {
      Header: 'Time off',
      accessor: (daySetting: any) => {
        const { dayDate, day, startTime, endTime } = daySetting
        const dayString = dayDate || moment.utc(day).format('MM/DD/YYYY')
        return `${dayString} ${startTime} - ${endTime}`
      },
      width: 100,
    },
    {
      Header: 'Reason',
      accessor: 'reason',
    },
    {
      Header: '',
      id: 'actions',
      width: 70,
      accessor: (daySetting: any, index: number) => {
        return (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <SecondaryButton
                buttonName='Delete'
                disabled={isReadOnly}
                style={{
                  height: 'unset',
                  padding: '.5rem 1.5rem',
                }}
                onClick={() => {
                  const newDaySettings = [...sortedDaySettings]
                  newDaySettings.splice(index, 1)
                  setDaySettings(newDaySettings)
                }}
              />
            </div>
          </div>
        )
      },
    },
  ] as Column[]

  return (
    <div className={styles.daySettingsManagerMain}>
      <div className={styles.daySettingsManagerContainer}>
        <TableTopper
          mainStyle={{
            padding: 0,
          }}
          containerStyle={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <TableTitle
            style={{
              padding: '1rem',
              paddingBottom: canSeeNewDaySettingInputs ? 0 : '1rem',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            Blocked off times
            <SecondaryButton
              buttonName='Add new time off?'
              disabled={isReadOnly}
              style={{
                height: 'unset',
                padding: '.5rem 1.5rem',
                display: canSeeNewDaySettingInputs ? 'none' : 'block',
              }}
              onClick={() => setCanSeeNewDaySettingInputs(true)}
            />
          </TableTitle>
          <div
            className={styles.addNewDaySettingContainer}
            style={{
              display: canSeeNewDaySettingInputs ? 'flex' : 'none',
            }}
          >
            <div className={styles.addNewDaySettingInputsHolder}>
              <TextField
                onChange={(e) =>
                  setNewDaySetting({
                    ...newDaySetting,
                    day: e.target.value as any, //needed because of the type of the day in the backend
                  })
                }
                value={newDaySetting.day || ''}
                InputLabelProps={{ shrink: true }}
                type='Date'
                label='Choose day'
                name='dayDateTo'
                sx={{ width: 130 }}
              />
              {['startTime', 'endTime'].map((timeType, idx) => {
                const label =
                  timeType === 'startTime' ? 'Start Time' : 'End Time'
                const disabledDueToMissingStartTime =
                  timeType === 'endTime' && !newDaySetting.startTime

                // if choosing the start time, the min time should be the workDayFrom
                // if choosing the end time, the min time should be the start time of the day setting
                const minTime =
                  timeType === 'startTime'
                    ? location.workDayFrom
                    : newDaySetting.startTime

                const value = newDaySetting[timeType as 'startTime' | 'endTime']

                return (
                  <TextField
                    key={`${timeType}-${idx}`}
                    type='time'
                    label={label}
                    name={timeType}
                    value={value || ''}
                    inputProps={{
                      min: minTime,
                      step: 60 * MINUTES_STEP,
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={isReadOnly || disabledDueToMissingStartTime}
                    onChange={(e) => {
                      setNewDaySetting({
                        ...newDaySetting,
                        [timeType]: e.target.value,
                      })
                    }}
                  />
                )
              })}
              <TextField
                onChange={(e) =>
                  setNewDaySetting({
                    ...newDaySetting,
                    reason: e.target.value,
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !createNewDaySettingButtonDisabled) {
                    handleNewDaySettingCreation()
                  }
                }}
                value={newDaySetting.reason || ''}
                InputLabelProps={{ shrink: true }}
                label='Reason'
                name='reason'
                sx={{ width: 200 }}
              />
              <PrimaryButton
                buttonName={createNewDaySettingButtonLabel}
                disabled={createNewDaySettingButtonDisabled}
                onClick={() => handleNewDaySettingCreation()}
              />
            </div>
          </div>
        </TableTopper>
        <div>
          <Table
            columns={columns}
            data={(sortedDaySettings || []) as Row<{}>[]}
            isDisplayMode={true}
          />
        </div>
      </div>
    </div>
  )
}
